import React from 'react';
import styled from '@emotion/styled';

import Container from '@/components/atoms/Container';
import CompanyStats from '@/components/molecules/CompanyStats';

import mq from '@/styles/mq';

interface StatProps {
  number?: React.ReactNode;
  label?: string | null;
}

interface Props {
  stats?: StatProps[];
}

const ServicesShopStatsRoot = styled(Container)`
  display: none;

  ${mq('lg')} {
    display: flex;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.spacing(5)};
    margin-bottom: ${({ theme }) => theme.spacing(10)};
  }
`;

const ServicesShopStats = ({ stats }: Props): JSX.Element => {
  return (
    <ServicesShopStatsRoot maxWidth="lg">
      <CompanyStats stats={stats} />
    </ServicesShopStatsRoot>
  );
};

export default ServicesShopStats;
