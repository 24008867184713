import React from 'react';
import styled from '@emotion/styled';

import Container from '@/components/atoms/Container';
import ContactBox from '@/components/molecules/ContactBox';

import mq from '@/styles/mq';

import ImageDataType from '@/types/data/image';

interface Props {
  background?: ImageDataType;
  title?: React.ReactNode;
  content?: React.ReactNode;
  button?: {
    text: React.ReactNode;
    link: string;
  };
}

const ServicesShopContactBoxRoot = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing(5)};
  margin-bottom: ${({ theme }) => theme.spacing(5)};

  ${mq('lg')} {
    margin-top: ${({ theme }) => theme.spacing(5)};
    margin-bottom: ${({ theme }) => theme.spacing(7)};
  }
  /* h2 {
    color: ${({ theme }) => theme.color.black.light};
  }
  p {
    color: ${({ theme }) => theme.color.textSecondary.main};
  }
  a {
    &:hover p {
      color: ${({ theme }) => theme.color.primary.main};
    }
    p {
      color: ${({ theme }) => theme.color.white.main};
    }
  } */
`;

const ServicesShopContactBox = (props: Props): JSX.Element => {
  return (
    <ServicesShopContactBoxRoot maxWidth={1040}>
      <ContactBox {...props} />
    </ServicesShopContactBoxRoot>
  );
};

export default ServicesShopContactBox;
