import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@/components/organisms/Layout';
import Seo from '@/components/atoms/Seo';
import ServicesShopContainer from '@/containers/ServicesShop/ServicesShopContainer';

import {
  PrismicServicesShopPage,
  PrismicServicesShopPageBodyCompanystats,
} from '../../../graphql-types';

interface Props {
  data: {
    prismicServicesShopPage: PrismicServicesShopPage;
    prismicServicesShopPageBodyCompanystats: PrismicServicesShopPageBodyCompanystats;
  };
}

export const query = graphql`
  query ShopServicesPage {
    prismicServicesShopPage {
      data {
        meta_title
        meta_description
        ...ServicesShopHero
        ...ServicesShopPresentation
      }
    }
    prismicServicesShopPageBodyCompanystats {
      primary {
        type
      }
      items {
        label {
          text
        }
        number {
          raw
        }
      }
    }
    prismicServicesShopPageBody1Contactbox {
      primary {
        type
        title {
          raw
        }
        content {
          raw
        }
        button_name {
          raw
        }
        link
        image {
          url
          alt
        }
      }
    }
  }
`;

const Services = ({ data }: Props): JSX.Element => {
  const { meta_title, meta_description } =
    data?.prismicServicesShopPage?.data || {};
  return (
    <Layout invertNav type="magasin">
      <Seo
        title={meta_title || 'title'}
        description={meta_description || 'description'}
      />
      <ServicesShopContainer data={data} type="magasin" />
    </Layout>
  );
};

export default Services;
