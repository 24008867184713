import React from 'react';
import { RichText } from 'prismic-reactjs';
import styled from '@emotion/styled';

import ServicesShopHero from '@/containers/ServicesShop/ServicesShopHero';
import ServicesShopPresentation from '@/containers/ServicesShop/ServicesShopPresentation';
import ServicesShopContactBox from '@/containers/ServicesShop/ServicesShopContactBox';
import ServicesShopStats from '@/containers/ServicesShop/ServicesShopStats';

import {
  PrismicServicesShopPage,
  PrismicServicesShopPageServicesGroupType,
  PrismicServicesShopPageBodyCompanystats,
  PrismicServicesShopPageBody1Contactbox,
} from '../../../graphql-types';

interface Props {
  data: {
    prismicServicesShopPage: PrismicServicesShopPage;
    prismicServicesShopPageBodyCompanystats: PrismicServicesShopPageBodyCompanystats;
    prismicServicesShopPageBody1Contactbox: PrismicServicesShopPageBody1Contactbox;
  };
  type: string;
}

const ServicesShopContainerRoot = styled.section``;

const ServicesShopContainer = ({ data, type }: Props): JSX.Element => {
  const {
    hero_title,
    hero_content,
    hero_image,
    hero_button,
    hero_button_link,
    services,
  } = data?.prismicServicesShopPage?.data;

  const companyStats = data?.prismicServicesShopPageBodyCompanystats || {};

  const { primary } = data?.prismicServicesShopPageBody1Contactbox || {};

  const HeroProps = {
    title: <RichText render={hero_title?.raw} />,
    description: <RichText render={hero_content?.raw} />,
    background: {
      url: hero_image?.url,
      alt: hero_image?.alt,
    },
    button: {
      label: hero_button,
      path: hero_button_link,
    },
  };

  const ServicesProps = {
    services: services?.map(
      (el: PrismicServicesShopPageServicesGroupType | null) => {
        const {
          service_title,
          service_content,
          service_button,
          service_button_link,
          service_image,
        } = el || {};

        return {
          title: <RichText render={service_title?.raw} />,
          description: <RichText render={service_content?.raw} />,
          button: {
            label: service_button,
            path: service_button_link,
          },
          background: {
            url: service_image?.url,
            alt: service_image?.alt,
          },
        };
      },
    ),
  };

  const ContactBoxProps = {
    type: primary?.type,
    title: <RichText render={primary?.title?.raw} />,
    background: {
      url: primary?.image?.url,
      alt: primary?.image?.alt,
    },
    content: <RichText render={primary?.content?.raw} />,
    button: {
      text: <RichText render={primary?.button_name?.raw} />,
      link: primary?.link,
    },
  };

  //   const StatsProps = {
  //     stats: {
  //       number: <RichText render={companyStats?.items?.number?.raw} />,
  //       label: companyStats?.items?.label?.text,
  //     };
  //   };

  const StatsProps = {
    stats: companyStats?.items?.map((item) => ({
      number: <RichText render={item?.number?.raw} />,
      label: item?.label?.text,
    })),
  };

  return (
    <ServicesShopContainerRoot>
      <ServicesShopHero {...HeroProps} />
      <ServicesShopPresentation {...ServicesProps} />
      {primary && <ServicesShopContactBox {...ContactBoxProps} />}
      <ServicesShopStats {...StatsProps} />
    </ServicesShopContainerRoot>
  );
};

export default ServicesShopContainer;
