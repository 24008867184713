import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import Button from '@/components/atoms/Button';
import Link from '@/components/atoms/Link';
import Background from '@/components/atoms/Background';
import Container from '@/components/atoms/Container';
import Typography from '@/components/atoms/Typography';
import Grid from '@/components/atoms/Grid';

import mq from '@/styles/mq';

import ImageDataType from '@/types/data/image';
import LinkDataType from '@/types/data/link';

interface Props {
  title?: React.ReactNode;
  description?: React.ReactNode;
  button?: LinkDataType;
  background?: ImageDataType;
}

const ServicesShopHeroRoot = styled(Grid)``;

const ServicesShopHeaderContainer = styled(Container)`
  padding-top: ${({ theme }) => theme.spacing(5)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  ${mq('lg')} {
    padding-top: ${({ theme }) => theme.spacing(15)};
    padding-left: ${({ theme }) => theme.spacing(4)};
    padding-right: ${({ theme }) => theme.spacing(4)};
  }
`;
const LeftSideGrid = styled(Grid)`
  /* padding-left: ${({ theme }) => theme.spacing(2)}; */
  /* padding-right: ${({ theme }) => theme.spacing(2)}; */
  order: 2;

  ${mq('lg')} {
    padding-left: 0;
    padding-right: ${({ theme }) => theme.spacing(3.5)};
    order: 1;
  }
`;
const RightSideGrid = styled(Grid)`
  order: 1;
  position: relative;
  flex-direction: column;
  ${mq('md')} {
    /* padding-left: ${({ theme }) => theme.spacing(2)}; */
    /* padding-right: ${({ theme }) => theme.spacing(2)}; */
    justify-content: space-between;
    align-items: flex-start;
  }
  ${mq('lg')} {
    order: 2;
  }
`;
const Title = styled(Typography)`
  width: 80%;
  margin-top: ${({ theme }) => theme.spacing(5)};
  /* color: ${({ theme }) => theme.color.black.main}; */
  z-index: 600;
  color: ${({ theme }) => theme.color.white.main};
  position: absolute;
  top: 0%;
  left: 12%;
  ${mq('xxs')} {
    top: 5%;
    left: 12%;
  }
  ${mq('md')} {
    width: 50%;
    top: 12%;
    left: 28%;
  }
  ${mq('lg')} {
    margin-top: 0;
    width: 80%;
    top: 27%;
    left: 11%;
  }
`;
const HeroBg = styled(Background)`
  width: 100%;
  height: 400px;
  border-radius: 10px;
  ${mq('xxs')} {
    height: 300px;
  }
  ${mq('md')} {
    /* width: 550px; */
    /* height: 750px; */
    margin: auto;
    width: 60%;
    height: 300px;
  }

  ${mq('lg')} {
    border-radius: 10px;
    width: 100%;
    height: 400px;
  }
`;
const Description = styled(Typography)`
  flex-basis: 70%;
  margin-top: ${({ theme }) => theme.spacing(3)};
  font-weight: 500;
  p {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;
const Cta = styled(Button)`
  flex-basis: 25%;
  margin-top: ${({ theme }) => theme.spacing(3)};
`;

const ServicesShopHero = ({
  title,
  description,
  button,
  background,
}: Props): JSX.Element => {
  const { label, path } = button || {};
  return (
    <ServicesShopHeroRoot container>
      <ServicesShopHeaderContainer maxWidth="lg">
        <LeftSideGrid container>
          {/* <LeftSideGrid xxs={12} lg={5}>
            <Title variant="h1">{title}</Title>
          </LeftSideGrid> */}
          <RightSideGrid xxs={12} lg={12} mb={6}>
            <Title variant="h1">{title}</Title>
            <HeroBg background={background} overlay={0.2} />
          </RightSideGrid>
        </LeftSideGrid>
        <RightSideGrid container>
          <Description variant="textMd">{description}</Description>
          {path && (
            <Cta as={Link} {...{ to: path }} size="sm">
              {label}
            </Cta>
          )}
        </RightSideGrid>
      </ServicesShopHeaderContainer>
    </ServicesShopHeroRoot>
  );
};

export const query = graphql`
  fragment ServicesShopHero on PrismicServicesShopPageDataType {
    hero_title {
      raw
    }
    hero_content {
      raw
    }
    hero_button
    hero_button_link
    hero_image {
      url
      alt
    }
  }
`;

export default ServicesShopHero;
