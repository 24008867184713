import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import Grid from '@/components/atoms/Grid';
import Container from '@/components/atoms/Container';
import Button from '@/components/atoms/Button';
import Link from '@/components/atoms/Link';
import Img from '@/components/atoms/Img';
import Typography from '@/components/atoms/Typography';

import mq from '@/styles/mq';

import ImageDataType from '@/types/data/image';
import LinkDataType from '@/types/data/link';

interface Props {
  title?: React.ReactNode;
  description?: React.ReactNode;
  button?: LinkDataType;
  background?: ImageDataType;
}

interface ServiceShopProps {
  services: Props[];
}

const ServiceContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;

  ${mq('lg')} {
    padding-top: ${({ theme }) => theme.spacing(5)};
    padding-left: ${({ theme }) => theme.spacing(4)};
    padding-right: ${({ theme }) => theme.spacing(4)};
  }
`;

const ServicesShopPresentationRoot = styled(Grid)`
  padding-top: ${({ theme }) => theme.spacing(8)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  ${mq('lg')} {
    padding-top: ${({ theme }) => theme.spacing(0)};
  }
`;

const Title = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(5)};

  ${mq('lg')} {
    margin-top: 0;
  }
`;
const Description = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(3)};
  font-weight: 500;
`;
const Cta = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(3)};
`;
const HeroBg = styled(Img)`
  background-size: cover;
  /* transform: skew(2deg, 0deg); */
  width: 100%;
  height: 260px;
  border-radius: 10px;
  ${mq('md')} {
    height: 400px;
  }

  ${mq('lg')} {
    border-radius: 10px;
  }
`;
const SectionLeft = styled.section`
  padding-bottom: ${({ theme }) => theme.spacing(5)};
  padding-top: ${({ theme }) => theme.spacing(5)};
  flex-basis: 40%;
`;

const SectionRight = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-basis: 50%;
`;
const ServiceItemRoot = styled.section`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(0)};
  &:nth-of-type(2) {
    a {
      display: none;
    }
  }
  ${mq('md')} {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    &:nth-of-type(odd) {
      flex-direction: row !important;
      > div:nth-of-type(1) {
        padding-right: ${({ theme }) => theme.spacing(10)};
        padding-left: ${({ theme }) => theme.spacing(10)};
      }
    }
    &:nth-of-type(even) {
      flex-direction: row-reverse !important;
      > div:nth-of-type(2) {
        padding-right: ${({ theme }) => theme.spacing(10)};
        padding-left: ${({ theme }) => theme.spacing(10)};
      }
    }
  }
`;
const Service = ({
  title,
  description,
  button,
  background,
}: Props): JSX.Element => {
  const { label, path } = button || {};
  return (
    <ServiceItemRoot>
      <SectionLeft>
        <HeroBg src={background?.url || ''} alt={background?.alt || ''} />
      </SectionLeft>
      <SectionRight>
        <Title variant="h2">{title}</Title>
        <Description>{description}</Description>
        <Cta as={Link} {...{ to: path }} size="sm">
          {label}
        </Cta>
      </SectionRight>
    </ServiceItemRoot>
  );
};

const ServicesShopPresentation = ({
  services,
}: ServiceShopProps): JSX.Element => {
  return (
    <ServicesShopPresentationRoot container>
      <ServiceContainer maxWidth="lg">
        {services?.map((service: Props, index: number) => (
          <Service {...service} key={index.toString()} />
        ))}
      </ServiceContainer>
    </ServicesShopPresentationRoot>
  );
};

export const query = graphql`
  fragment ServicesShopPresentation on PrismicServicesShopPageDataType {
    services {
      service_title {
        raw
      }
      service_content {
        raw
      }
      service_button
      service_button_link
      service_image {
        url
        alt
      }
    }
  }
`;

export default ServicesShopPresentation;
